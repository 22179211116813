.App {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  text-align: center;
  background-color: #000000;
}

.content {
  height: 100%;
  bottom: 0;
  background-image: url("./assets/images/bg.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
}

.icon-game {
  margin: 10px;
  padding: 10px;
  border: #595a5e;
  border-style: solid;
  border-width: 1px;
  height: 160px;
  width: 160px;
  background-color: rgb(255, 255, 255, 0);
}

.icon-game:hover {
  background-color: rgb(255, 255, 255, 0.3);
  cursor: pointer;
}
